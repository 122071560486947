
import { ROOT_ACTIONS } from "@/store/actions";
import { Component, VModel, Vue } from "vue-property-decorator";

import * as ClaimCategoryService from '@/services/ClaimCategoryService';

@Component
export default class NewFactCheckCategory extends Vue {
  @VModel({ required: true }) show!: boolean;

  inputs = {
    name: "",
  };
  
  loading = {
    submit: false,
  }

  async submit(){
    try {
      await ClaimCategoryService.CreateClaimCategory(this.inputs.name)
      this.$emit('added')
      this.show = false;
      this.inputs.name = '';
    } catch (error) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'error',
        text: 'error'
      })
    }
  }
}
